import axios from 'axios';
import omwConfig from '@/config/config';
import store from '@/store';

export async function submitClick(activityDetails, engineerName, campaign, datasetId) {
  const clickOrigin = store.getters.clickOrigin;
  const clickSubmissionData = {
    apptNumber: activityDetails.apptNumber,
    apptStatus: activityDetails.status,
    apptDate: activityDetails.date,
    campaignId: campaign.campaignId,
    engineerName,
    datasetId,
    clickDate: new Date(),
    clickOrigin,
  };
  return axios.post(omwConfig.marketing.recordClickUrl, clickSubmissionData, {
    headers: {
      'x-api-key': omwConfig.omwServer.apiKey,
    },
  });
}

export async function getCampaign() {
  const datasetId = store.getters.datasetId;
  if (!datasetId) return;
  const clickOrigin = store.getters.clickOrigin;

  // Allow forcing of marketing campaign for preview in portal
  const uri = window.location.search.substring(1);
  const params = new URLSearchParams(uri);
  const marketingPreviewUrl = params.get('marketingPreviewUrl');
  const clickUrl = params.get('clickUrl');
  const activityDetails = store.getters.activityDetails;
  const submissionParams = new URLSearchParams();

  if (marketingPreviewUrl) {
    return {
      url: marketingPreviewUrl,
      clickUrl,
    };
  }
  // Deal with retrieving a marketing campaign
  let url = omwConfig.marketing.retrievalUrl;
  if (omwConfig.marketing.oldStyle) {
    // Caters for the old URL path in use with OFSC customers
    url += `/${omwConfig.marketing.customer}`;
  }
  if (omwConfig.marketing.demo) {
    submissionParams.append('categories', encodeURIComponent(omwConfig.marketing.demoCustomer));
  } else if (activityDetails[omwConfig.marketing.fieldName]) {
    submissionParams.append('categories', encodeURIComponent(activityDetails[omwConfig.marketing.fieldName]));
  }
  if (activityDetails.apptNumber) submissionParams.append('apptNumber', activityDetails.apptNumber);
  if (activityDetails.date) submissionParams.append('apptDate', activityDetails.date);
  if (activityDetails.status) submissionParams.append('apptStatus', activityDetails.status);
  if (datasetId) submissionParams.append('datasetId', datasetId);
  if (clickOrigin) submissionParams.append('clickOrigin', clickOrigin);

  const { data } = await axios.get(url, {
    params: submissionParams,
    headers: {
      'x-api-key': omwConfig.omwServer.apiKey,
    },
  });

  if (!data.url) {
    throw new Error('No campaign found');
  }

  if (!data.clickUrl) data.clickUrl = omwConfig.marketing.defaultClickUrl;

  return data;
}
