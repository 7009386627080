<template>
  <div>
    <div v-if="!rescheduleDetails">
      <span class="columns">
        <span v-show="activityDetails" class="column is-half span-block">
          <appointment-details :width="mapWidth" />
          <here-map
            v-if="showMap"
            :height="mapHeight"
            :width="$omwConfig.here.mapWidth"
            :api-key="$omwConfig.here.apiKey"
            :imperial="$omwConfig.here.imperial"
            :show-zoom="$omwConfig.here.showZoom"
          />
        </span>
        <span class="column is-half">
          <engineer-panel
            :photo-source="photoSource"
            :logos="logos"
            :resource-type="resourceType"
            :licence-plate="engineerDetails.licencePlate"
          />
          <span v-if="marketingEnabled && !marketingCampaignFailed" class="pt-4 span-block">
            <marketing
              v-show="marketingReady"
              ref="marketing"
              @campaign-failed="marketingFailed"
              @campaign-loaded="nowShowMarketing"
            />
            <b-skeleton :height="engineerPanelHeight" size="is-large" :active="!marketingReady"></b-skeleton>
          </span>
          <additional-info v-if="additionalInfoEnabled" />
          <span style="text-align: center" class="pt-5 faint-box span-block">
            <!-- eslint-disable vue/no-v-html -->
            <p
              v-if="contactUsEnabled"
              class="is-size-5 has-text-weight-semibold"
              v-html="
                $t('main-rearrange-appointment-text', {
                  contactCentreNumber: $omwConfig.display.contactCentreNo,
                })
              "
            ></p>
            <!-- eslint-enable vue/no-v-html -->
            <span class="column has-text-centered mb-2 span-block">
              <span v-if="trustpilotEnabled" style="display: block; max-height: 40px; padding: 2em 0em 5em 0em">
                <trustpilot
                  :style="trustpilotPadding"
                  :data-locale="$omwConfig.trustpilot.dataLocale"
                  :data-template-id="$omwConfig.trustpilot.dataTemplateId"
                  :data-business-unit-id="$omwConfig.trustpilot.dataBusinessUnitId"
                  :data-style-height="$omwConfig.trustpilot.dataStyleHeight"
                  :data-style-width="$omwConfig.trustpilot.dataStyleWidth"
                  :data-theme="$omwConfig.trustpilot.dataTheme"
                  :url="$omwConfig.trustpilot.url"
                />
              </span>

              <timeago :datetime="currentDate" :auto-update="10 * 1000" />
            </span>
          </span>
        </span>
      </span>
    </div>
    <div v-else>
      <appointment-rebook-confirmation style="height: 80vh" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { mapGetters } from 'vuex';

import EngineerPanel from '@/components/EngineerPanel.vue';
import HereMap from '@/components/Heremap.vue';
import Timeago from '@/components/Timeago.vue';
import AppointmentDetails from '@/components/AppointmentDetails.vue';
import AppointmentRebookConfirmation from '@/components/appointmentBooking/AppointmentRebookConfirmation.vue';

import AdditionalInfo from '@/components/optional/AdditionalInfo.vue';

export default defineComponent({
  components: {
    AppointmentDetails,
    AppointmentRebookConfirmation,
    HereMap,
    EngineerPanel,
    Timeago,
    AdditionalInfo,
    Trustpilot: () => import(/* webpackPrefetch: true */ '@/components/optional/Trustpilot.vue'),
    Marketing: () => import(/* webpackPrefetch: true */ '@/components/optional/Marketing.vue'),
  },
  data() {
    return {
      mapMounted: false,
      marketingCampaignFailed: false,
      engineerPanelHeight: undefined,
      logos: this.$omwConfig.logos,
      marketingReady: false,
    };
  },
  computed: {
    ...mapGetters([
      'currentDate',
      'rescheduleDetails',
      'activityDetails',
      'engineerDetails',
      'token',
      'apptInPast',
      'apptInFuture',
    ]),
    showMap() {
      return this?.activityDetails?.latitude || this?.engineerDetails?.latitude;
    },
    resourceType() {
      return this?.engineerDetails?.type;
    },
    photoSource() {
      if (!this?.engineerDetails) return '';
      const engineerKeys = Object.keys(this?.engineerDetails);
      const defaultPhoto = `img/${this.$omwConfig.display.defaultEngineerPhoto}`;

      if (this.apptInFuture && this.$omwConfig.display.defaultEngineerPhoto) {
        return defaultPhoto;
      } else if (this.apptInFuture) {
        return undefined;
      }
      if (!engineerKeys.includes('photo') || this.engineerDetails['photo'].match(/No photo available/gi)) {
        if (this.$omwConfig.display.defaultEngineerPhoto) {
          return defaultPhoto;
        } else {
          return undefined;
        }
      }

      return this.engineerDetails['photo'];
    },
    trustpilotPadding() {
      let paddingTop = '10em';
      return {
        paddingBottom: '1em',
        paddingTop,
      };
    },
    relativeToMarketing() {
      return {
        height: this.marketingHeight,
        width: '30em',
      };
    },
    marketingHeight() {
      return 10;
    },
    mapHeight() {
      if (this.isMobile) {
        return this.$omwConfig.here.mapHeightMobile;
      }
      return this.$omwConfig.here.mapHeightDesktop;
    },
    mapWidth() {
      if (this.isMobile) {
        return this.$omwConfig.here.mapWidthMobile;
      }
      return this.$omwConfig.here.mapWidthDesktop;
    },
    disclaimerStyle() {
      return {
        fontStyle: 'italic',
        color: 'black',
      };
    },
  },
  updated() {
    this.engineerPanelHeight = this.$refs?.engineerPanel?.$el?.offsetHeight;
  },
  methods: {
    nowShowMarketing(evt) {
      if (evt) {
        this.marketingReady = true;
      }
    },
    marketingFailed() {
      this.marketingCampaignFailed = true;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';
$margin: 0.25rem;

.content-desktop {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.content-mobile {
  display: flex;
}
@media screen and (max-width: 768px) {
  .content-desktop {
    display: none;
  }
  .content-mobile {
    display: block;
  }
}

.engineer-marketing-flex-container {
  display: flex;
  flex-flow: wrap row-reverse;
  margin: 0 (-$margin);
  text-align: center;
}

.margin-wrap {
  flex: 1 1 auto;
  padding: 1rem;
  border: $margin;
}

.faint-box {
  @if variable-exists(additional-info-panel-border) {
    border-style: solid;
    border-width: 1px;
    border-color: $additional-info-panel-border;
    border-radius: 4px;
  }
}

.span-block {
  display: block;
}
</style>
