<template>
  <div
    v-if="publishedCampaign && publishedCampaign.url"
    id="divMarketing"
    class="has-text-centered"
    @click="recordClick"
  >
    <a v-if="clickUrl" :href="clickUrl" target="_new"
      ><img :src="publishedCampaign.url" style="border-radius: 5px"
    /></a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { defineComponent } from '@vue/composition-api';
import { submitClick, getCampaign } from '@/services/marketing';

export default defineComponent({
  data() {
    return {
      publishedCampaign: {},
    };
  },
  computed: {
    ...mapGetters(['activityDetails', 'datasetId']),
    /**
     * If a clickUrl is provided, ensure it starts with https:// otherwise the link is appended to the current origin
     */
    clickUrl() {
      if (!this?.publishedCampaign?.clickUrl) return;
      if (
        this.publishedCampaign.clickUrl.startsWith('https://') ||
        this.publishedCampaign.clickUrl.startsWith('http://')
      ) {
        return this.publishedCampaign.clickUrl;
      }
      return `https://${this.publishedCampaign.clickUrl}`;
    },
  },
  async created() {
    try {
      this.publishedCampaign = await getCampaign();
      if (this.publishedCampaign) {
        this.$emit('campaign-loaded', true);
      }
    } catch (err) {
      this.$emit('campaign-failed', true);
    }
  },
  methods: {
    async recordClick() {
      try {
        await submitClick(this.activityDetails, this.engineerName, this.publishedCampaign, this.datasetId);
      } catch (err) {
        console.error(err);
      }
    },
  },
});
</script>
